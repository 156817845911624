.primary-header {
    border-bottom: 1px solid var(--clr-neutral-400);
    background-color: var(--clr-white);
    position: relative;
    width: 100dvw;
    height: 66px;
    display: flex;
    justify-content: center;
}

.container {
    position: relative;
    height: calc(100lvh - 66px);
    width: 100dvw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (width >= 60em) {
    .container {
        height: calc(100dvh - 66px);
        width: 100dvw;
        display: block;
    }
    .primary-header {
        display: block;
    }
}

.map {
    width: 100dvw;
    height: 100%;
}
