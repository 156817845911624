.results {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--clr-white);
    height: 100%;
    overflow: auto;
    width: 100%;
    transition: all 0.75s ease;
}

.reward-identity {
    display: flex;
    flex-direction: row;
}

.reward-stats {
    display: flex;
    gap: 1rem;
}

ul {
    padding-left: 1rem;
    list-style: none;
    padding-block: 0.5rem;
}

li:not(:first-child) {
    margin-top: 1rem;
}

@media (width >= 60em) {
    .results {
        position: absolute;
        border-radius: 0.2rem;
        height: 100%;
        overflow: auto;
        width: 30rem;
    }
}

.results img {
    height: 25px;
    width: 25px;
}

.results-header {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    padding-inline: 1rem;
    margin-bottom: 0.75rem;

    position: sticky;
    top: 0;
    background-color: white;
    padding-bottom: 1rem;
}

.results-header img {
    cursor: pointer;
}

div.legend {
    display: flex;
    flex-direction: column;
}
