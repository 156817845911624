.search {
    position: absolute;
    top: 2.5rem;
}

@media (width >= 60em) {
    .search {
        padding-left: 2.5rem;
    }
}

.search-input {
    border-radius: 25rem;
    outline: none;
    border: none;
    height: 3rem;
    width: 18rem;
    text-align: left;
    padding-left: 2rem;
    color: var(--clr-primary-400);
    font-weight: var(--fw-regular);
}

.search-input::placeholder {
    opacity: 50%;
}

.search-icon {
    position: absolute;
    background-color: transparent;
    border: none;
    cursor: pointer;
    top: 0.7rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
}
