.header-wrapper,
.nav-list {
    display: flex;
}
.header-wrapper {
    justify-content: space-between;
    align-items: center;
    padding-inline: 1rem;
}
.nav-list {
    padding-right: 1.5rem;
    gap: 3rem;
}

.site-logo {
    background-color: transparent;
    border: none;
}

.purpose-text {
    display: none;
}

@media (width >= 60em) {
    .purpose-text {
        margin-right: 1rem;
        display: block;
    }
}
